<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-overlay :show="loadingState">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                     <b-row v-for="(setLevel, index) in applicationData.level_info" :key="index">
                          <b-col lg="3" sm="3">
                              <ValidationProvider name="Variety" vid='variety' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="variety"
                                  slot-scope="{ valid, errors }"
                              >
                                 <template v-slot:label>
                                {{$t('seedsSeeds.variety')}} <span class="text-danger">*</span>
                              </template>
                               <b-form-input
                                  id="quantity"
                                  hidden
                                  v-model="setLevel.variety_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <b-form-input
                                  id="quantity"
                                  readonly
                                  v-model="setLevel.variety"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <!-- <b-form-select
                                  plain
                                  v-model="setLevel.variety_id"
                                  :options="varietyList"
                                  id="crop_name_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  </b-form-select> -->
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="3" sm="3">
                              <ValidationProvider name="Stock Quantity" vid='stock_quantity' rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="stock_quantity"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('seedsSeeds.stock_quantity')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="stock_quantity"
                                  readonly
                                  v-model="setLevel.stock_quantity"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                          </b-col>
                         <b-col lg="3" sm="3">
                           <ValidationProvider name="Quantity" vid='quantity' rules="required">
                             <b-form-group
                                 class="row"
                                 label-cols-sm="5"
                                 label-for="quantity"
                                 slot-scope="{ valid, errors }"
                             >
                               <template v-slot:label>
                                 {{ $t('seedsSeeds.quantity')}}<span class="text-danger">*</span>
                               </template>
                               <b-form-input
                                   id="quantity"
                                   readonly
                                   v-model="setLevel.quantity"
                                   :state="errors[0] ? false : (valid ? true : null)"
                                   @keyup="getFarmerList(setLevel.quantity,index)"
                               ></b-form-input>
                               <div class="invalid-feedback">
                                 {{ errors[0] }}
                               </div>
                             </b-form-group>
                           </ValidationProvider>
                         </b-col>
                          <b-col lg="3" sm="3">
                              <ValidationProvider vid="approve_quantity" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="5"
                                  label-for="approve_quantity"
                                  slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('seedsSeeds.approve_quantity') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                  id="approve_quantity"
                                  v-model="setLevel.approve_quantity"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @keyup="getFarmerList(setLevel.approve_quantity,index)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                              </ValidationProvider>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col lg="6" sm="4" md="8" xl="12">
                              <ValidationProvider vid="reason">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="reason"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('seedsSeeds.comment')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="reason"
                                      v-model="applicationData.reason"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="4" md="8" xl="12">
                              <ValidationProvider vid="reason_bn">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="reason_bn"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('seedsSeeds.comment_bn')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="reason_bn"
                                      v-model="applicationData.reason_bn"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                        <b-col lg="6" sm="4" md="8" xl="12">
                          <ValidationProvider name="Distribution Date" vid="distribution_date" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="distribution_date"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsSeeds.distribution_date')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input class="form-control flatpickr"
                                            v-model="applicationData.distribution_date"
                                            placeholder="Select Date"
                                            id="distribution_date"
                                            :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { applicationRequestShow, varietyList, applicationRequestStatusChange } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        saveBtnName: this.id ? this.$t('seedsSeeds.approve') : this.$t('globalTrans.save'),
        recommendation: '',
        applicationData: {
          id: '',
          org_id: 0,
          fiscal_year_id: 0,
          applicant_type_id: 0,
          applicant_id: 0,
          production_season_id: 0,
          seed_class_id: 0,
          crop_type_id: 0,
          crop_name_id: 0,
          level_info: [],
          measurement_unit_id: 0,
          application_date: 0,
          reason: 'N/A',
          reason_bn: 'N/A',
          distribution_date: ''
        },
        comment: {
            reason: 'N/A',
            reason_bn: 'N/A',
            distribution_date: ''
        }
    }
  },
  async created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.applicationData = tmp
      const params = Object.assign({}, this.applicationData, {
        level_info: [],
        reason: 'N/A',
        reason_bn: 'N/A',
        distribution_date: ''
      })
      this.applicationData = params
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
      await RestApi.getData(seedFertilizerServiceBaseUrl, varietyList + '/' + this.id).then(response => {
            if (response.success) {
                this.applicationData.level_info = []
                response.data.forEach(element => {
                     const stepName = {
                        variety_id: element.variety_id,
                        variety: this.getVarietyName(element.variety_id),
                        stock_quantity: element.stock_quantity,
                        quantity: element.quantity,
                        approve_quantity: element.approve_quantity,
                        quantity2: element.quantity
                    }
                    this.applicationData.level_info.push(stepName)
                })
            }
        })
      const loadinState = { loading: false, listReload: false }
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
    }
  },
  mounted () {
    flatpickr('.flatpickr', {})
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    getFarmerList (value, Index) {
      const maxValue = this.applicationData.level_info[Index].stock_quantity > this.applicationData.level_info[Index].quantity2 ? this.applicationData.level_info[Index].quantity2 : this.applicationData.level_info[Index].stock_quantity
      if (value > maxValue) {
        this.applicationData.level_info[Index].approve_quantity = maxValue
      } else {
        this.applicationData.level_info[Index].approve_quantity = value
      }
    },
    async getApplicationRequestData () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, `${applicationRequestShow}/${this.$props.id}`)
      if (result.success) {
        this.recommendation = result.data
       }
      this.loader = false
    },
    async createData () {
    this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
        // result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${applicationRequestUpdate}/${this.id}`, this.applicationData)
         result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${applicationRequestStatusChange}/${this.id}`, this.applicationData)
        loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
    //   this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-6')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getVarietyList (cropNameId) {
        const variety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(doc => doc.crop_name_id === cropNameId)
        if (this.$i18n.locale === 'en') {
            variety.forEach(element => {
                    const stepName = {
                    variety_id: element.value,
                    variety: element.text_en,
                    quantity: ''
                }
                this.applicationData.level_info.push(stepName)
            })
        } else {
             variety.forEach(element => {
                    const stepName = {
                    variety_id: element.value,
                    variety: element.text_bn,
                    quantity: ''
                }
                this.applicationData.level_info.push(stepName)
            })
        }
    },
    getVarietyName (Id) {
        const variety = this.$store.state.SeedsFertilizer.commonObj.VarietyList.find(doc => doc.value === Id)
        if (variety) {
            return this.$i18n.locale === 'bn' ? variety.text_bn : variety.text_en
        } else {
            return null
        }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
