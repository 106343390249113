<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                  <b-form-group
                  class="row"
                  label-cols-sm="4"
                  label-for="fiscal_year_id"
                  >
                  <template v-slot:label>
                  {{$t('warehouse_config.fiscal_year')}}
                  </template>
                  <b-form-select
                  plain
                  v-model="search.fiscal_year_id"
                  :options="fiscalList"
                  id="fiscal_year_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col md="4">
            <b-form-group
            class="row"
            label-cols-sm="5"
            :label="$t('seedsSeeds.applicantType')"
            label-for="productionSeason"
            >
            <v-select name="croptypeID"
            v-model="search.applicant_type_id"
            label="text"
            :options= applyByList
            :placeholder="$t('globalTrans.select')"
            />
            </b-form-group>
          </b-col>
          <b-col >
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('seedsSeeds.application_request') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered striped hover :items="applicatonListData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                     <template v-slot:cell(applicant_type_id)="data">
                        <span class="capitalize" v-if="data.item.applicant_type_id == 1">{{ $t('seedsSeeds.company') }}</span>
                        <span class="capitalize" v-else-if="data.item.applicant_type_id == 2">{{ $t('seedsSeeds.farmer') }}</span>
                        <span class="capitalize" v-else>{{ $t('seedsSeeds.dealer') }}</span>
                      </template>
                    <template v-slot:cell(fiscal_year)="data">
                      {{ $n(data.item.fiscal_year.split('-')[0], { useGrouping: false })}}-{{$n(data.item.fiscal_year.split('-')[1], { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(view)="data">
                      <a href="javascript:" class="btn_table_action table_action_view" title="Application Request list" v-b-modal.modal-5 @click="setRow(data.item)"><i class="fas fa-eye"></i></a>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status == 1" title="Click to Approve" v-b-modal.modal-6 size="sm" @click="appro(data.item)"><i class="fas fa-check"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle"  v-if="data.item.status == 1" title="Click to Reject" size="sm" @click="reject(data.item)"><i class="fas fa-times"></i></a>
                       <span class="badge badge-success" v-if="data.item.status == 2">{{$t('globalTrans.approved')}}</span>
                      <span class="badge badge-danger" v-if="data.item.status == 3">{{$t('globalTrans.rejected')}}</span>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-5" size="lg" :title="$t('seedsSeeds.application_request')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailsView :id="testId"/>
    </b-modal>
      <b-modal id="modal-6" size="xl" :title="$t('seedsSeeds.application_approve')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Approve :id="testId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { applicationRequestPendingList, applicationRequestStatusChange, applicationRequestRejectStatus, companyFarmerInfoList, dealerList } from '../../api/routes'
import DetailsView from './Form'
import Approve from './Approve'

export default {
  name: 'UiDataTable',
  components: {
    DetailsView, Approve
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        fiscal_year_id: 0,
        applicant_type_id: '',
        org_id: this.$store.state.dataFilters.orgId
      },
      applyByList: [
        { value: 1, text: this.$t('seedsSeeds.company') },
        { value: 2, text: this.$t('seedsSeeds.farmer') },
        { value: 3, text: this.$t('seedsSeeds.dealer') }
      ],
      testId: 0,
      rows: [],
      applicatonListData: [],
      applicantList: [],
      dealerList: [],
      formData: {
        comments: '',
        comments_bn: ''
      },
      applicantData: {
        applicantName: ''
      }
    }
  },
  computed: {
    fiscalList: function () {
          const fiscalyearData = this.$store.state.commonObj.fiscalYearList
          return fiscalyearData.map(item => {
              if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
              } else {
              return { value: item.value, text: item.text_en }
              }
          })
    },
   formTitle () {
      return (this.testId === 0) ? this.$t('seedsSeeds.application_request') : this.$t('seedsSeeds.application_request')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('seedsSeeds.fiscal_year'), class: 'text-center' },
          { label: this.$t('seedsSeeds.production_season'), class: 'text-center' },
          { label: this.$t('seedsSeeds.seed_type'), class: 'text-center' },
          { label: this.$t('seedsSeeds.applicantType'), class: 'text-center' },
          { label: this.$t('warehouse_service.view'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'production_seasson_bn' },
          { key: 'crop_type_name_bn' },
          { key: 'applicant_type_id' },
          { key: 'view' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year' },
          { key: 'production_seasson' },
          { key: 'crop_type_name' },
          { key: 'applicant_type_id' },
          { key: 'view' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    }
  },
  watch: {
    loadingState: function (newVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, {
                fiscal_year_id: this.search.fiscal_year_id.value,
                applicant_type_id: this.search.applicant_type_id.value,
                page: this.pagination.currentPage,
                per_page: this.pagination.perPage
                })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const response = await RestApi.getData(seedFertilizerServiceBaseUrl, companyFarmerInfoList)
      if (response.success) {
          this.applicantList = response.data
      }
      const dealerResponse = await RestApi.getData(seedFertilizerServiceBaseUrl, dealerList)
      if (dealerResponse.success) {
          this.dealerList = dealerResponse.data
      }
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, applicationRequestPendingList, params)
      if (result.success) {
        this.$store.dispatch('setList', this.relationalData(result.data.data))
        this.paginationData(result.data)
      } else {
        this.applicatonListData = []
      }
       this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    relationalData (data) {
      let tmpCropType = {}
      let tmpFiscalYear = {}
      let tmpProductionSeason = {}
      const listData = data.map((item, index) => {
          tmpCropType = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(cropType => cropType.status === 1 && cropType.value === item.crop_type_id)
          tmpFiscalYear = this.$store.state.commonObj.fiscalYearList.filter(fiscalYear => fiscalYear.status === 0 && fiscalYear.value === item.fiscal_year_id)
          tmpProductionSeason = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(productionSeason => productionSeason.status === 1 && productionSeason.value === item.production_season_id)
          const orgData = {
            crop_type_name: tmpCropType[0] !== undefined ? tmpCropType[0].text_en : '',
            crop_type_name_bn: tmpCropType[0] !== undefined ? tmpCropType[0].text_bn : '',
            fiscal_year: tmpFiscalYear[0] !== undefined ? tmpFiscalYear[0].text_en : '',
            production_seasson: tmpProductionSeason[0] !== undefined ? tmpProductionSeason[0].text_en : '',
            production_seasson_bn: tmpProductionSeason[0] !== undefined ? tmpProductionSeason[0].text_bn : ''
          }
          return Object.assign({}, item, orgData)
        })
      this.applicatonListData = listData
      return listData
    },
    toggleStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.putData(seedFertilizerServiceBaseUrl, `${applicationRequestStatusChange}/${item.id}`, this.formData).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Application Approved Successfully',
            color: '#D6E09B'
          })
        } else {}

        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      }, error => {
        if (error) {
          //
        }
        this.$toast.error({
            title: 'Error',
            message: 'Failed to Application Approved',
            color: '#D6E09B'
          })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    toggleStatusForReject (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.putData(seedFertilizerServiceBaseUrl, `${applicationRequestRejectStatus}/${item.id}`, this.formData).then(response => {
        if (response.success) {
          this.$toast.success({
            title: 'Success',
            message: 'Application Rejected Successfully',
            color: '#D6E09B'
          })
        } else {}

        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      }, error => {
        if (error) {
          //
        }
        this.$toast.error({
            title: 'Error',
            message: 'Failed to Application Rejected',
            color: '#D6E09B'
          })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      })
    },
    setRow (item) {
      this.testId = item.id
    },
    appro (item) {
      this.testId = item.id
    },
    approve (item) {
      const comments = this.$i18n.locale === 'bn' ? 'মন্তব্য' : 'Comments'
      const commentsBn = this.$i18n.locale === 'bn' ? 'মন্তব্য (বাংলা)' : 'Comments (bn)'
      this.$swal({
        title: this.$t('globalTrans.approveMsg'),
        showCancelButton: true,
        html: `
        <div class="form-group text-left mt-3">
          <label for="formGroupExampleInput" class="small">${comments}</label>
          <textarea id="comments" v-model="formData.comments" class="swal2-input" rows="3"></textarea>
        </div>
        <div class="form-group text-left">
          <label for="formGroupExampleInput" class="small">${commentsBn}</label>
          <textarea id="comments_bn" v-model="formData.comments_bn" class="swal2-input" rows="3"></textarea>
        </div>
        `,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.comments = document.querySelector('#comments').value
          this.formData.comments_bn = document.querySelector('#comments_bn').value
          this.toggleStatus(item)
        }
      })
    },
    reject (item) {
      const comments = this.$i18n.locale === 'bn' ? 'বাতিল করা কারণ' : 'Reject Reason'
      const commentsBn = this.$i18n.locale === 'bn' ? 'বাতিল করা কারণ(বাংলা)' : 'Reject Reason (bn)'
      this.$swal({
        title: this.$t('globalTrans.rejectMsg'),
        showCancelButton: true,
        html: `
        <div class="form-group text-left mt-3">
          <label for="formGroupExampleInput" class="small">${comments}</label>
          <textarea id="comments" v-model="formData.comments" class="swal2-input" rows="3"></textarea>
        </div>
        <div class="form-group text-left">
          <label for="formGroupExampleInput" class="small">${commentsBn}</label>
          <textarea id="comments_bn" v-model="formData.comments_bn" class="swal2-input" rows="3"></textarea>
        </div>
        `,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.formData.comments = document.querySelector('#comments').value
          this.formData.comments_bn = document.querySelector('#comments_bn').value
          this.toggleStatusForReject(item)
        }
      })
    }
  }
}
</script>
